import { useContext, useState, useEffect } from "react";
import Footer from "../../../components/Footer"
import { FormEntrada2 } from "../../../components/Forms/FormEntrada";
import Header from "../../../components/Header"
import { PortariaContext } from "../../../Context/PortariaContext";
import { Button } from "react-bootstrap";
import ModalAviso from "../../../components/Modals";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from "react-router-dom";
import { ClienteContext } from "../../../Context/ClienteContext";

const PageDetalharEntrada = () => {

    const dadosEntradaContext = useContext(PortariaContext); //se o context for um objeto, nao colocar chaves na const - 
    const [statusModal, setStatusModal] = useState(false);
    const {setGlobalMsg} = useContext(ClienteContext);
    const navigate = useNavigate();
    console.log('dadosEntrada detalhar = ', dadosEntradaContext);

    useEffect(() => {
    setGlobalMsg([false, '']);
    
  },[]);

    async function excluirEntrada(embId) {
        console.log('ENTROU EXCLUIR EMB -', embId);
        const operacaoDeletar = await RequisicaoPost('/excluirEntrada', { id: Number(dadosEntradaContext.dadosEntrada.id) })
        setStatusModal(false);

        console.log('resposta server = ', operacaoDeletar.status)
        if (operacaoDeletar.status === "success") {
            setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            return navigate('/portariaMain');
        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação", "danger"])
        }
    }

    async function editarEmb(entradaId) {
        console.log('Iniciou edicao');
        navigate(`/portariaEditarEntrada/${Number(dadosEntradaContext?.dadosEntrada?.id)}`)
    }

    return (
        <>

            <ModalAviso
                titulo='Confirmar Exclusão'
                texto="Tem certeza que de deseja excluir esse registro ?"
                obs='Essa ação não poderá ser desfeita'
                statusModal={statusModal}
                onRequestClose={() => setStatusModal(false)}
                aoConfirmar={() => excluirEntrada(Number(dadosEntradaContext.id))}
            ></ModalAviso>

           

            <Header />

            <h1>Detalhar Entrada</h1>

            <Button
                onClick={() => editarEmb()}
            >
                Editar Entrada
            </Button>

            <Button
                variant="danger"
                onClick={() => setStatusModal(true)}
            >
                Excluir
            </Button>

            <FormEntrada2
                //functionOnSubmit={editEntrada}
                tipoForm="detalharEntrada"
                statusPlaca={'Detalhes Entrada'}
                placa={dadosEntradaContext.dadosEntrada.Veiculo.placa}
                tipo={dadosEntradaContext.dadosEntrada.tipoEntrada}
                nome={dadosEntradaContext.dadosEntrada.Cliente.nome}
                embarcacao={dadosEntradaContext.dadosEntrada.embarcacao}
                veiculo={dadosEntradaContext.dadosEntrada.Veiculo.modelo}
                valor={Number(dadosEntradaContext.dadosEntrada.Pagamento.valor)}
                formaPgto={dadosEntradaContext.dadosEntrada.Pagamento.formaPgto}
                observacao={dadosEntradaContext.dadosEntrada.observacao}
            />
            <Footer />
        </>
    )
}

export default PageDetalharEntrada;