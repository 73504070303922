import React from "react";

import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import Limpeza from "../../../shared/Types/Limpeza";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";


interface Props {
    limpezas: Limpeza[] 
    roteDetalhar: string
}


const TableLimpezas = ({ limpezas, roteDetalhar }: Props) => {
    console.log('RoteDetalhar = ', roteDetalhar);
    console.log('limpezas inside table = ', limpezas);

    //const {setDadosVenda } = useContext(VendaContext)
    //const { setDadosCompra } = useContext(CompraContext)
    const { setDadosLimpeza } = useContext(EmbarcacaoContext)


    const { currentPage} = useContext(ClienteContext);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState<Limpeza[]>([]);

    const navigate = useNavigate();


    useEffect(() => {
            setDataTable(limpezas)
    }, [limpezas])


    function atualizaFiltros(novofiltro: object) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro }));
    }

    async function aplicarFiltro() {
        const dadosFiltrados = await RequisicaoPost(`/limpezas/?page=${currentPage}&size=50`, filtrosAplicados);
        console.log('dados Filtrados = ', dadosFiltrados);
        setDataTable(dadosFiltrados?.listaEmbarcacoesFiltradas?.rows);
    }

    function RedirectTo(paramters:any, rote:string) {
        console.log('Parametros Venda = ', paramters);
        console.log('rote = ', rote);
        setDadosLimpeza(paramters);
        return navigate(rote);
    }


    //{new Date(item?.data).toLocaleDateString()}
    //console.log(`dataTable tsx = `, dataTable)
    return (
        <>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Cliente</th>
                        <th>Embarcacao</th>
                        <th>Funcionário</th>
                        <th>Observação</th>
                       
                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item:any) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.data?.toString().slice(0,10)}</td>
                            <td>{item?.Embarcaco?.Cliente?.nome}</td>
                            <td>{ item?.Embarcaco?.id + "-" + item?.Embarcaco?.nome + " - " + item?.Embarcaco?.modeloCascoEmb}</td>
                            <td>{item?.Funcionario?.nome}</td>
                            <td>{item?.observacao.slice(0,30)}</td>
                       
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item,roteDetalhar )}>+</Button></td>
                            

                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableLimpezas;