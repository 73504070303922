import { Route, Routes } from 'react-router-dom';
import PageEmbarcacoesMain from "../pages/Embarcacoes/PageEmbarcacoesMain";
import PageDetalharEmbarcacao from "../pages/Embarcacoes/PageDetalharEmbarcacao";
import PageEditarEmbarcacao from "../pages/Embarcacoes/PageEditarEmbarcacao";
import PageNovaEmbarcacao from "../pages/Embarcacoes/PageNovaEmbarcacao"
import PageResultadoPesquisaEmbarcacao from "../pages/Embarcacoes/PageResultadoPesquisaEmbarcacao";
import PageChecklistMain from "../pages/Embarcacoes/CheckLists/PageChecklistMain";
import PageNovoChecklist from "../pages/Embarcacoes/CheckLists/PageNovoChecklist";
import PageDetalharChecklist from "../pages/Embarcacoes/CheckLists/PageDetalharChecklist";
import PageEditarChecklist from "../pages/Embarcacoes/CheckLists/PageEditarChecklist";
import PageMovimentacoesMain from '../pages/Embarcacoes/Movimentacoes/PageMovimentacoesMain';
import PageNovaMovimentacao from '../pages/Embarcacoes/Movimentacoes/PageNovaMovimentacao';
import PageDetalharMovimentacao from '../pages/Embarcacoes/Movimentacoes/PageDetalharMovimentacao';
import PageEditarMovimentacao from '../pages/Embarcacoes/Movimentacoes/PageEditarMovimentacao';
import PageNovaLimpeza from '../pages/Embarcacoes/Limpezas/PageNovaLimpeza';
import PageLimpezasMain from '../pages/Embarcacoes/Limpezas/PageLimpezasMain';
import PageDetalharLimpeza from '../pages/Embarcacoes/Limpezas/PageDetalharLimpeza';
import PageEditarLimpeza from '../pages/Embarcacoes/Limpezas/PageEditarLimpeza';
import PageFuncionariosMain from '../pages/Funcionarios/PageFuncionariosMain';
import PageNovoFuncionario from '../pages/Funcionarios/PageNovoFuncionario';
import PageEditarFuncionario from '../pages/Funcionarios/PageEditarFuncionario';

export default function EmbarcacoesRoutes () {
    return (
        
            <Routes>
                <Route path="/embarcacoesMain" element={<PageEmbarcacoesMain/>}/>
                <Route path ="/detalharEmbarcacao" element={<PageDetalharEmbarcacao/>}/>
                <Route path="/editarEmbarcacao" element={<PageEditarEmbarcacao/>}/>
                <Route path="/PageNovaEmbarcacao" element={<PageNovaEmbarcacao/>}/>
                <Route path="/pageResultadoPesquisaEmbarcacao" element={<PageResultadoPesquisaEmbarcacao/>}/>
                <Route path="/pageCheckListMain" element={<PageChecklistMain/>}/>
                <Route path="/PageNovoCheckList" element={<PageNovoChecklist/>}/>
                <Route path="/detalharChecklist" element={<PageDetalharChecklist/>}/>
                <Route path="/editarChecklist" element={<PageEditarChecklist/>}/>

                <Route path="/movimentacoesMain" element={<PageMovimentacoesMain/>}/>
                <Route path='/novaMovimentacao' element={<PageNovaMovimentacao/>}/>
                <Route path="/detalharMovimentacao" element={<PageDetalharMovimentacao/>}/>
                <Route path="/editarMovimentacao" element={<PageEditarMovimentacao/>}/>

                <Route path="/limpezasMain" element={<PageLimpezasMain/>} />
                <Route path="/novaLimpeza" element={<PageNovaLimpeza/>} />
                <Route path="/detalharLimpeza" element={<PageDetalharLimpeza/>} />
                <Route path="/editarLimpeza" element={<PageEditarLimpeza/>} />

                <Route path="/funcionarios" element={<PageFuncionariosMain/>} />
                <Route path="/novoFuncionario" element={<PageNovoFuncionario/>} />
                <Route path="/editarFuncionario" element={<PageEditarFuncionario/>} />

            </Routes>
        
    )
}