import react, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import Funcionario from "../../../shared/Types/Funcionario";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import TableFuncionariosMain from "../../../components/Tables/TableFuncionariosMain";


const PageFuncionariosMain = () => {

    const [dataTable, setDataTable] = useState<Funcionario[]>([]);


    async function getAllFuncionarios() {
        const funcionarios = await RequisicaoGet(`/funcionarios/?page=${0}&size=50`);
        console.log('funcionarios =', funcionarios);
        setDataTable(funcionarios.rows);
        console.log(`dataTable = `,dataTable);
    }

    useEffect(() => {getAllFuncionarios()},[]);



    return (
        <>
            <Header/>
            <h1>Page Funcionarios Main</h1>

            <Button href="/novoFuncionario">Novo Funcionário</Button>

            <TableFuncionariosMain
                funcionarios={dataTable}
                roteEditar='/editarFuncionario'                
            />
            <Footer/>
        </>
    )
}

export default PageFuncionariosMain;