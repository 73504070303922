import Footer from "../../../components/Footer"
import FormEditarFuncionario from "../../../components/Forms/Funcionarios/FormEditarFuncionario";
import Header from "../../../components/Header"

const PageEditarFuncionario = () => {
    return (
        <div>
            <Header/>
            <h1>Editar Funcionario</h1>

            <FormEditarFuncionario
                formType="editar"
                isDisabled={false}

            />

            <Footer/>
        </div>
    )
}

export default PageEditarFuncionario;