import React , {useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { FormEntrada2 } from "../../../components/Forms/FormEntrada";
import { useNavigate } from 'react-router-dom';
import { PortariaContext } from "../../../Context/PortariaContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";



export default function PageEditarEntrada(props){

    const  dadosEntradaContext  = useContext(PortariaContext); //se o context for um objeto, nao colocar chaves na const - 
    const navigate = useNavigate();

    const editEntrada = async (data) => {

        console.log('data editEntrada= ', data);

        const paymentId = dadosEntradaContext.dadosEntrada.pagamentoId;   
        const updatedFormData = {
            valor: data.valor,
            formaPgto: data.pagamento,
            observacao: data.observacao

        }

        await RequisicaoPost(`/UpdateEntradaPayment/${paymentId}`,updatedFormData ).then(
            (response) => {
            if(response.status === "success"){
                return navigate("/portariaMain")
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    return navigate("/");
                }
            }
        })
    }

    return (
        <>
            <Header />
            <h1>Editar Entrada</h1>
            <FormEntrada2
                functionOnSubmit={editEntrada}
                statusPlaca={'Editar Entrada'}
                placa= {dadosEntradaContext.dadosEntrada.Veiculo.placa}
                tipo={dadosEntradaContext.dadosEntrada.tipoEntrada}
                nome={dadosEntradaContext.dadosEntrada.Cliente.nome}
                embarcacao={dadosEntradaContext.dadosEntrada.embarcacao}
                veiculo={dadosEntradaContext.dadosEntrada.Veiculo.modelo}
                valor={Number(dadosEntradaContext.dadosEntrada.Pagamento.valor)}
                formaPgto={dadosEntradaContext.dadosEntrada.Pagamento.formaPgto}
                observacao={dadosEntradaContext.dadosEntrada.observacao}
            />
            <Footer/>
        </>

    )
}
