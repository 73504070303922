
export function verificaCPF(cpfCliente) {
    //console.log('CPF A SER CHECADO = ', cpfCliente);
    
    if(cpfCliente === "" || cpfCliente === null || cpfCliente === undefined){
        return true
    }
    
    const regexCPFformat = /([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/
    if (cpfCliente.match(regexCPFformat) && cpfCliente.length === 11) {
        return true;
    } else {
        return false;
    }


}

export  function validaPlacaVeiculo(inputPlate){
    const regexOldFormat = /^[a-zA-Z]{3}[0-9]{4}$/;
    const regexNewFormat = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/;
    
    if(inputPlate === undefined || inputPlate === ""){
        //console.log('RETORNA FALSO')
        return true;
    }
 

    if (inputPlate.match(regexOldFormat) || inputPlate.match(regexNewFormat)) {
        return true;
    }else{
        console.log('false');
        return false;
    }
    
}   


