import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../../../config/requisicoes/requisicaoPOST";

interface Props {
    movimentacao?: any
    isDisabled?: any
    formType?: string
}

interface Proprietario {
    value?: number
    label?: string
    id?: number
    nome?: string
}


const FormNovaMovimentacao = ({ movimentacao, isDisabled, formType }: Props) => {

    console.log(`data movimentacao inside form = `, movimentacao?.dadosEmbarcacao?.data?.slice(0, 10));
    console.log(`isDisabled = `, isDisabled)

    const { handleSubmit, register, formState: { errors }, } = useForm();
    //const {pesquisaProprietarios} = useContext(EmbarcacaoContext)

    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    //const [proprietarioInfos, setProprietarioInfos] = useState<Proprietario>({});
    const [proprietarioInfos, setProprietarioInfos] = useState<any>({});

    const [embsSelecionadas, setEmbsSelecionadas] = useState([0]);
    const [embSelecionada, setEmbSelecionada] = useState<any>({});
    const [modeloEmbSelecionado, setModeloEmbSelecionado] = useState("");
    const [typeOfMovement, setTypeOfMovement] = useState('');
    const navigate = useNavigate();


    function defineEmbStatus(typeOfMovement: string) {
        if (typeOfMovement === "Entrada") {
            return "Inativo";
        }else if (typeOfMovement === "Saída") {
            return "Ativo";
        }else if (typeOfMovement === "Retorno ST") {
            return "Fora Temp";            
        }else if (typeOfMovement === "Saída Temporária") {
            return "Ativo";
        }else{
            throw new Error('Tipo de movimentação inválido !');
        }
    }
    async function pesquisaProprietarios(data: any) {

        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        console.log("data = ", newData);
        console.log('typeofmovement = ', typeOfMovement);

        const embStatus = defineEmbStatus(typeOfMovement);

        console.log('embStatus = ', embStatus);

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', {newData, embStatus})
            setResultadoPesquisa(resultado.rows);
            console.log('resultado pesquisa = ', resultado.rows);
            
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map((item: Proprietario) => ({ value: item?.id, label: item?.id + '-' + item?.nome }))
            return proprietariosFormatado;
        }
    }

    function listarEmbsDoCliente() {
        //let clienteSelecionado = [{Embarcacoes: object}]
        const clienteSelecionado: any = resultadoPesquisa.filter((item: Proprietario) => item?.id === proprietarioInfos.value)
        setEmbsSelecionadas(clienteSelecionado[0]?.Embarcacoes);

    }

    useEffect(listarEmbsDoCliente, [proprietarioInfos, resultadoPesquisa, typeOfMovement]);

    function changeEmbSelect(newEmbNome: string) {
        const FiltraNovoModelo: any = embsSelecionadas?.filter((item: any) => item.nome.slice(0, newEmbNome.length) === newEmbNome);//[0]?.modeloCascoEmb;
        const novoModelo = FiltraNovoModelo[0].modeloCascoEmb;
        const idEmbSelecionado = Number(FiltraNovoModelo[0].id) //embsSelecionadas?.filter((item) => item.nome === newEmbNome)[0]?.id;
        setEmbSelecionada({ nome: newEmbNome, id: Number(idEmbSelecionado) });
        setModeloEmbSelecionado(novoModelo)
    }

    async function salvarDados(data: any) {
        console.log('embSelecionada = ', embSelecionada);
        console.log('proprietario = ', proprietarioInfos);
        data = { ...data, embarcacaoId: Number(embSelecionada.id), clienteId: Number(proprietarioInfos.value) }
        
        
        console.log(`dados Form = `, data);
        console.log('data mov - ', new Date(data?.data));
        console.log('data limite - ', new Date('2024-12-18'));

        if (new Date(data?.data) < new Date('2024-12-18')) {
            alert('Por questões técnicas, não é possivel criar uma movimentação com data anterior a 18/12/2024.');
            return;
        } 

        const salvar = await RequisicaoPost('/adicionarNovaMov', data);
        console.log('salvar= ', salvar);
        if (salvar.status === "success") {
            navigate('/movimentacoesMain')
        }else{
            alert('Ocorreu um erro ao salvar a Movimentacao !');
            return;
        }
    }

    //pesquisaProprietarios()

    return (
        <>
            <Form onSubmit={handleSubmit(salvarDados)}>
                <section>
                    <Form.Group key="D" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Data:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('data', { required: true })} disabled={isDisabled} defaultValue={movimentacao?.data?.toString().slice(0, 10)} type="date"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="E" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Tipo Movimentacao:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Select  {...register('tipoMovimentacao', { required: true })} disabled={isDisabled} onChange={(e) => setTypeOfMovement(e.target.value)}>
                                    <option>Alteração Proprietário</option>
                                    <option>Entrada</option>
                                    <option>Retorno ST</option>
                                    <option>Saída Temporária</option>
                                    <option>Saída</option>
                                    <option selected>{movimentacao?.tipoMovimentacao}</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    {formType === "readOnly" ?
                        <>
                            <Form.Group key="F" as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label sm={1}>
                                        Proprietário:
                                    </Form.Label>
                                    <Col xs={5}>
                                        <Form.Control disabled={isDisabled} defaultValue={movimentacao?.Embarcaco?.Cliente?.nome}></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group key="F2" as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label sm={1}>
                                        Embarcação:
                                    </Form.Label>
                                    <Col xs={5}>
                                        <Form.Control disabled={isDisabled} defaultValue={movimentacao?.Embarcaco?.nome}></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </>


                        :
                        <>
                            <Form.Group key="F3" as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label sm={1}>
                                        Proprietário:
                                    </Form.Label>
                                    <Col xs={5}>
                                        <AsyncSelect {...register("cliente")} placeholder={"Digite nome Cliente"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }}></AsyncSelect>
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label sm={1}>
                                    Embarcação:
                                </Form.Label>
                                <Col xs={5}>
                                    <Form.Select
                                        {
                                        ...register(
                                            "embarcacao",
                                            {
                                                required: true,
                                                validate: value => value !== "Selecione"
                                            },

                                        )
                                        }
                                        onChange={(event) => changeEmbSelect(event.target.value)}
                                        disabled={isDisabled}
                                    >
                                        <option></option>
                                        <option>Selecione</option>
                                        {embsSelecionadas?.map((item: any) => (
                                            <option key={item.id}>{item.nome}</option>
                                        ))}
                                    </Form.Select>
                                    {errors !== undefined && errors?.embarcacao?.type === 'validate' && <p>Selecione uma Embarcação</p>}

                                </Col>
                            </Form.Group>
                        </>
                    }

                    <Form.Group key="E2" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Status Contrato:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Select  {...register('contrato', { required: true })} disabled={isDisabled}>
                                    <option>Pendente</option>
                                    <option>Emitido</option>
                                    <option>Assinado</option>
                                    <option>Não se Aplica</option>
                                    <option selected>{movimentacao?.contrato}</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-4">
                        <Col>
                            <Form.Label>Observação:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("observacao")} as="textarea" rows={5} disabled={isDisabled} defaultValue={movimentacao?.observacao} ></Form.Control>
                        </Col>

                    </Form.Group>




                    {formType === "readOnly" ? "" :
                        <Button type="submit">Salvar</Button>

                    }

                </section>

            </Form>
        </>
    )
}

export default FormNovaMovimentacao;