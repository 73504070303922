import React from "react";
import FormEditarLimpeza from "../../../../components/Forms/Embarcacoes/Limpezas/FormEditarLimpeza";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";

const PageEditarLimpeza = () => {
    return (


        <>
            <Header/>
            <h1>Editar Limpeza</h1>

            <FormEditarLimpeza
                formType="editar"
            />

            <Footer/>
        </>
    )
}

export default PageEditarLimpeza;