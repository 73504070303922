import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import RequisicaoPost from "../../../../../config/requisicoes/requisicaoPOST";
import { EmbarcacaoContext } from "../../../../../Context/EmbarcacaoContext";
import RequisicaoPut from "../../../../../config/requisicoes/requisicaoPUT";

interface Props {
    limpeza?: any
    isDisabled?: any
    formType?: string
}

const FormEditarLimpeza = ({ limpeza, isDisabled, formType }: Props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [embId, setEmbId] = useState(0);
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [isLoading, setIsLoading] = useState(['Salvar', false]);

    const { dadosLimpeza } = useContext(EmbarcacaoContext)
    console.log('DADOS LIMPEZA = , ', dadosLimpeza);

    const navigate = useNavigate();




    async function pesquisaEmbarcacao(data: any) {
        console.log('iniciou pesquisa embarcacao por id');

        const newData = { ...data, data }
        console.log('new data = ', newData);

        const resultado = await RequisicaoPost('/pesquisarEmbarcacaoes', { formaPesquisa: "Id Embarcação", infoParaPesquisa: newData.data })
        console.log('resultado = ', resultado.resultado.rows);
        setResultadoPesquisa(resultado.resultado.rows);

        const embarcacoes = resultado.resultado.rows;
        const embarcacoesFormatado = embarcacoes.map((item: any) => ({ value: item?.id, label: item?.id + '-' + item?.Cliente?.nome.slice(0, 20) + '/' + item?.tipo + '/' + item?.modeloCascoEmb }))

        console.log('embarcacoes-formatado = ', embarcacoesFormatado);

        return embarcacoesFormatado;
    }

    function setEmbSelecionada(data: any) {
        console.log('data = ', data);
        setEmbId(data.value);
    }


    async function atualizarDados(data: any) {
        setIsLoading(['Carregando...', true]);
        //await new Promise(resolve => setTimeout(resolve, 5000));


        //const dataAtual = new Date();
        //const dataHoraAtual = dataAtual.toISOString().replace('T', ' ').replace('Z', '');
        //console.log(dataHoraAtual); // Output: "2023-02-20 14:30:00"

        //data.data = dataHoraAtual;
        //data.embarcacaoId = embId;

        console.log('ENTROU ATUALIZAR DADOS = ');
        console.log('data salvar dados = ', data);
        data.id = Number(dadosLimpeza.id);

        const resultado = await RequisicaoPut('/limpeza', { data });
        console.log('resultado = ', resultado);

        if (resultado.status === "success") {
            console.log('sucesso');
            navigate('/limpezasMain');
        } else {
            alert('Ocorreu um erro ao atualizar a Limpeza !');
            setIsLoading(['Salvar', false]);

            return;
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit(atualizarDados)}>
                <section>
                    <Form.Group key="a" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Data:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('data', { required: true })} disabled={isDisabled} defaultValue={dadosLimpeza?.data?.slice(0, 10)} type="date"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="b" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Tipo:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Select  {...register('tipoServico', { required: true })} disabled={isDisabled} defaultValue={dadosLimpeza?.tipoServico}>
                                    <option>Limpeza</option>
                                    <option>Repasse</option>
                                    <option selected>Limpeza</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="c" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Embarcacao:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control {...register("embarcacao")} disabled={true} defaultValue={dadosLimpeza?.Embarcaco?.id + "-" + dadosLimpeza?.Embarcaco?.nome + " - " + dadosLimpeza?.Embarcaco?.modeloCascoEmb} ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="d" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Funcionário:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('funcionario', { required: true })} disabled={true} defaultValue={dadosLimpeza?.Funcionario?.nome}>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="e" as={Col} className="mb-4">
                        <Col>
                            <Form.Label>Observação:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("observacao")} as="textarea" rows={5} disabled={isDisabled} defaultValue={dadosLimpeza?.observacao} ></Form.Control>
                        </Col>

                    </Form.Group>

                    {formType === "editar" &&

                        <Button type="submit" disabled={!!isLoading[1]}>{isLoading[0]}</Button>
                    }


                </section>

            </Form>
        </>
    )
}

export default FormEditarLimpeza;