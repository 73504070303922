import React, { useContext, useState } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import FormEntrada, { FormEntrada2 } from "../../../components/Forms/FormEntrada"
import { PortariaContext } from "../../../Context/PortariaContext"
import api from "../../../config/configApi";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import requisicaoPOST from "../../../config/requisicoes/requisicaoPOST.js";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET.js"

export default function CadastrarNovaPlaca() {

    const { placa } = useContext(PortariaContext);
    const navigate = useNavigate();
    const [errorMsg , setErrorMsg] = useState('');



    console.log('Conetxt.placa - ', placa/*.placaTxt.toUpperCase()*/);

    
    async function openGate(){
        const open = await fetch('http://192.168.1.99/?abertura-activate',
            {
                method: 'GET',
                mode: 'no-cors'

                
            }
        )
        console.log(open);
    }
    
    
    
    
    const salvarPlaca = async (formData) => {

        console.log('iniciou salvar placa \n');
        //event.preventDefault();
        console.log('formData = ', formData);
        await requisicaoPOST('/CadastrarPlaca', formData).then(
            (response) => {
            console.log('RESP DEF - ', response);
            console.log(' ***** response.ok = ', response.status);
            if (response.statusText === "OK"){
                //openGate()
                return navigate("/portariaMain")
            }else{
                setErrorMsg(<div className="alert alert-danger" role="alert">
                    Erro ao Cadastrar Placa-Tente Novamente

                </div>);

            }
        }
        )
    }

    return (
        <>
            <Header />

            {errorMsg}
            <FormEntrada2
                placa={placa.toUpperCase()}
                statusPlaca="Cadastre a nova Placa"
                functionOnSubmit={salvarPlaca}
                tipoForm = "EntradaNovoCliente"
            />
            <Footer />
        </>
    )
}

