import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Form } from "react-router-dom";
import FormNovoFuncionario from "../../../components/Forms/Funcionarios/FormNovoFuncionario";

const PageNovoFuncionario = () => {

    return (
        <>
        <Header/>
        <h1> Cadastrar Novo Funcionário</h1>

        <FormNovoFuncionario/>
        <Footer/>
        </>
    )
}

export default PageNovoFuncionario;