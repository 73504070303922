import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import ComponentPagination from "../../Pagination";
import Movimentacao from "../../../shared/Types/Movimentacao";
import Button from "react-bootstrap/esm/Button";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import RequisicaoDownload from "../../../config/requisicoes/requisicaoDownload";
import Cookies from 'universal-cookie';



interface Props {
    movimentacoes: any
    roteDetalhar: string
    pesquisarMovimentacoes: (param: string) => Promise<Movimentacao[]>
}

interface Parameters {
    Checklist: { statusCheckList: string }
}

const TableMovimentacoes = ({ movimentacoes, roteDetalhar, pesquisarMovimentacoes }: Props) => {

    const cookies = new Cookies();
    const alc = cookies.get('alc');

    const { setDadosEmbarcacao } = useContext(EmbarcacaoContext)


    //const { currentPage } = useContext(ClienteContext);
    //const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState<Movimentacao[]>([]) || Promise<Movimentacao[]>;

    const navigate = useNavigate();



    useEffect(() => {
        setDataTable(movimentacoes)
    }, [movimentacoes, dataTable])



    /*function atualizaFiltros(novofiltro: object) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro }));
    }

    async function aplicarFiltro() {
        const dadosFiltrados = await RequisicaoGet(`/getAllEmbarcacoesFilter/?page=${currentPage}&size=50`);
        console.log('dados Filtrados = ', dadosFiltrados);
        setDataTable(dadosFiltrados?.listaEmbarcacoesFiltradas?.rows);
    }*/

    function RedirectTo(paramters: Parameters, rote: string) {

        let finalRote = rote;

        const newDataMovimentacao = { ...paramters, isReadOnly: 1 };
        setDadosEmbarcacao(newDataMovimentacao);

        if (rote !== "Checklist") {
            return navigate(finalRote)
        }

        if (rote === "Checklist") {

            if (rote === "Checklist" && paramters.Checklist === null) {
                return;
            }

            console.log('paramters?.Checklist?.statusCheckList = ', paramters?.Checklist?.statusCheckList);
            if (paramters?.Checklist?.statusCheckList === "Pendente") {
                finalRote = "/PageNovoCheckList"
            }

            if (paramters?.Checklist?.statusCheckList === "OK") {
                finalRote = "/detalharChecklist"
            }
        }

        return navigate(finalRote);
    }

    async function checkContract(movimentacao: Movimentacao) {

        const dataFormatada = new Date(movimentacao?.data.toString())

        console.log('movimentacao = ', movimentacao);

        if (movimentacao.tipoMovimentacao !== "Entrada"){
            return
        }



        const day = dataFormatada.getDate(); // Dia (1-31)
        const month = dataFormatada.getMonth() + 1; // Mês (0-11), por isso somamos 1
        const year = dataFormatada.getFullYear(); // Ano completo (ex: 2024)

        let contractData = {
            cliente: movimentacao?.Embarcaco?.Cliente?.nome,
            cpf: movimentacao?.Embarcaco?.Cliente?.cpf,
            movimentacaoId: movimentacao?.id,
            email: movimentacao?.Embarcaco?.Cliente?.email,
            telefone: movimentacao?.Embarcaco?.Cliente?.telefone,
            celular: movimentacao?.Embarcaco?.Cliente.celular,
            valor: movimentacao?.Embarcaco?.valorMensalidade,
            embarcacaoTipo: movimentacao?.Embarcaco?.tipo,
            embarcacaoNome: movimentacao?.Embarcaco?.nome,
            embarcacaoModeloCasco: movimentacao?.Embarcaco?.modeloCascoEmb,
            embarcacaoMotor: movimentacao?.Embarcaco?.motorEmbarcacao,
            embarcacaoTamanho: movimentacao?.Embarcaco?.tamanho,
            embarcacaoId: movimentacao?.Embarcaco?.id,
            embarcacaoNserieCascoEmb: movimentacao?.Embarcaco?.NserieCascoEmb,
            embarcacaoNserieMotorEmb: movimentacao?.Embarcaco?.NserieMotorEmb,
            data: {
                dia: day,
                mes: month,
                ano: year
            }
        }

        const blob: any = await RequisicaoDownload('/createNewContract', {
            contractData
        })

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${movimentacao?.Embarcaco?.Cliente?.nome}.pdf`);
        document.body.appendChild(link);
        link.click();
        const newData = await pesquisarMovimentacoes('');
        setDataTable(newData);


    }

    return (
        <>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Data</th>
                        <th>Tipo Mov.</th>
                        <th>Tipo Emb</th>
                        <th>Proprietário</th>
                        <th>Embarcação</th>

                        {alc >= 3 &&
                            <>
                                <th>Qtd Jets</th>
                                <th>Qtd Lanchas</th>
                                <th>Qtd Total Embs</th>
                                <th>Valor Emb</th>
                                <th>Valor Total Jets</th>
                                <th>Valor Total Lanchas</th>
                                <th>Total</th>
                                <th>Contrato</th>
                            </>
                        }

                        <th>Check-List</th>
                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item: Movimentacao) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.id}</td>

                            <td>{item?.data?.toString().slice(0, 10)}</td>


                            <td>
                                <Badge bg={item?.tipoMovimentacao === "Entrada" || item?.tipoMovimentacao === "Retorno" ? "success" : (item?.tipoMovimentacao === "Saída" ? "danger" : "warning")}>{item?.tipoMovimentacao}</Badge>
                            </td>
                            <td>{item?.Embarcaco?.tipo}</td>
                            <td>{item?.Embarcaco?.Cliente?.nome}</td>
                            <td>{item?.Embarcaco?.id + '-' + item?.Embarcaco?.nome + '' + item?.Embarcaco?.modeloCascoEmb}</td>

                            {alc >= 3 &&
                                <>
                                    <td>{item?.qtdadeJets}</td>
                                    <td>{item?.qtdadeLanchas}</td>
                                    <td>{item?.qtdadeTotal}</td>
                                    <td>{item?.Embarcaco?.valorMensalidade?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{item?.valorSubTotalJets?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{item?.valorSubTotalLanchas?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>{item?.valorTotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td onClick={() => checkContract(item)}>
                                        <Button variant={item?.contrato === "Assinado" ? "success" : (item?.contrato === "Pendente" ? "danger" : (item?.contrato === "Emitido" ? "warning" : ""))}>
                                            {item?.contrato === null ? " - " : item?.contrato}
                                        </Button>
                                    </td>
                                </>
                            }
                            <td onClick={(event) => RedirectTo(item, "Checklist")}>
                                <Button variant={item?.Checklist?.statusCheckList === "OK" ? "success" : (item?.Checklist?.statusCheckList === "Pendente" ? "danger" : (item?.Checklist?.statusCheckList === "-" ? "secondary" : ""))}>
                                    {item?.Checklist === null ? " - " : item?.Checklist?.statusCheckList}
                                </Button>
                            </td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item, roteDetalhar)}>+</Button></td>



                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableMovimentacoes