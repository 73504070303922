import Footer from "../../../Footer"
import Header from "../../../Header"
import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";

interface Props {
    funcionario?: any
    isDisabled?: any
    formType?: string
}


const FormNovoFuncionario = ({funcionario, isDisabled, formType }: Props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [embId, setEmbId] = useState(0);
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [isLoading, setIsLoading] = useState(['Salvar', false]);  


    const navigate = useNavigate();

    async function salvarDados(data: any) {
        setIsLoading(['Carregando...', true]);
        //await new Promise(resolve => setTimeout(resolve, 5000));


        const dataAtual = new Date();
        const dataHoraAtual = dataAtual.toISOString().replace('T', ' ').replace('Z', '');
        console.log(dataHoraAtual); // Output: "2023-02-20 14:30:00"

        data.data = dataHoraAtual;
        data.embarcacaoId = embId;

        console.log('ENTROU SALVAR DADOS = ');
        console.log('data salvar dados = ', data);

        const resultado = await RequisicaoPost('/novoFuncionario', { data });
        console.log('resultado = ', resultado);

        if(resultado.status === "success") {
            console.log('sucesso');
            navigate('/funcionarios');
        }else{
            alert('Ocorreu um erro ao salvar a Limpeza !');
            return;
        }
    }

    return (
        <>
            
            <Form onSubmit={handleSubmit(salvarDados)}>
                <section>
                    <Form.Group key="a" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Data Admissão:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('dataAdmissao', { required: true })} disabled={isDisabled} defaultValue={new Date().toISOString().slice(0, 10)} type="date"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="b" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Nome:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('nome', { required: true })} disabled={isDisabled}  type="text"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    <Form.Group key="c" as={Col}>
                        <Form.Group as={Row} >
                            <Form.Label sm={1}>
                                Cargo:
                            </Form.Label>
                            <Col xs={5}>
                                <Form.Control  {...register('cargo', { required: false })} disabled={isDisabled}  type="text"  ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>
                    

                    <Button type="submit" disabled={!!isLoading[1]}>{isLoading[0]}</Button>


                </section>

            </Form>
        </>
    )
}

export default FormNovoFuncionario;