import React, { useContext } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormEditarLimpeza from "../../../../components/Forms/Embarcacoes/Limpezas/FormEditarLimpeza";
import { Button } from "react-bootstrap"
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";
import { useNavigate } from "react-router-dom";
import RequisicaoDelete from "../../../../config/requisicoes/requisicaoDelete";


const PageDetalharLimpeza = () => {

    const { dadosLimpeza } = useContext(EmbarcacaoContext)

    const navigate = useNavigate()

    async function deletarLimpeza(id: Number) {

        console.log('id = ', id);

        // eslint-disable-next-line no-restricted-globals
        const answer = confirm("Deseja realmente excluir essa Limpeza ?")
        if (answer === true) {
            const deletar = await RequisicaoDelete('/limpeza', { id });

            if (deletar.status === 'success') {
                navigate('/limpezasMain')
            } else {
                alert('Ocorreu um erro ao tentar deletar o registro.')
                return;
            }
        }
    }


        return (
            <>

                <Header />

                <Button onClick={() => navigate('/editarLimpeza')}>Editar Limpeza</Button>

                <Button
                    variant="danger"
                    onClick={() => deletarLimpeza(Number(dadosLimpeza.id))}
                >
                    Excluir
                </Button>


                <h1>Detalhar Limpeza</h1>

                <FormEditarLimpeza
                    formType="detalhar"
                    isDisabled={true}
                />

                <Footer />
            </>
        )
    }


    export default PageDetalharLimpeza