import React, { useContext } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormEditarEmbarcacao from "../../../components/Forms/Embarcacoes/FormEditarEmbarcacao";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";

const PageEditarEmbarcacao = () => {

    const {dadosEmbarcacao} = useContext(EmbarcacaoContext);

    console.log('dados emb = ' , dadosEmbarcacao);
    return (
        <>
            <Header />
            <h1>Editar Embarcação</h1>
            <FormEditarEmbarcacao
                dados={dadosEmbarcacao}
                formType = "edit"
            />
            <Footer />
        </>
    )
}

export default PageEditarEmbarcacao;