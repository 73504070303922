import { useState, createContext } from 'react';
import RequisicaoPost from '../config/requisicoes/requisicaoPOST';

export const EmbarcacaoContext = createContext();

export const EmbarcacaoContextProvider = ({children}) => {
    const [dadosEmbarcacao, setDadosEmbarcacao] = useState({});
    const [dadosLimpeza, setDadosLimpeza] = useState({});
    const [dadosFuncionario, setDadosFuncionario] = useState({});

    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            //setResultadoPesquisa(resultado.rows);
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.id + '-' +item?.nome }))
            return proprietariosFormatado;
        }
    }

    return (
        <EmbarcacaoContext.Provider value={{dadosEmbarcacao,setDadosEmbarcacao,pesquisaProprietarios,dadosLimpeza, setDadosLimpeza, dadosFuncionario, setDadosFuncionario}}>
            {children}
        </EmbarcacaoContext.Provider>
    )
}