
import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import Limpeza from "../../../shared/Types/Limpeza";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";
import Funcionario from "../../../shared/Types/Funcionario";
import RequisicaoDelete from "../../../config/requisicoes/requisicaoDelete";

interface Props {
    funcionarios: Funcionario[]
    roteEditar: string
}

const TableFuncionariosMain = ({ funcionarios, roteEditar }: Props) => {

    const { currentPage } = useContext(ClienteContext);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState<Funcionario[]>([]);

    const { setDadosFuncionario } = useContext(EmbarcacaoContext)
    const navigate = useNavigate();


    useEffect(() => {
        setDataTable(funcionarios)
    }, [funcionarios])

    function RedirectTo(paramters: any, rote: string) {
        console.log('rote = ', rote);
        setDadosFuncionario(paramters);
        return navigate(rote);
    }

    async function deleteFuncionario(funcionarioId: number) {
        const deletar = await RequisicaoDelete('/funcionario', { id: funcionarioId });

        const confirmar = window.confirm("Deseja realmente excluir esse Funcionario ?")
        if (confirmar === false) {
            return
        } else {
            if (deletar.status === 'success') {
                navigate('/funcionarios');
                window.location.reload();
            } else {
                alert('Ocorreu um erro ao tentar deletar o registro.')
                return;
            }
        }
    }



    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Funcionário</th>

                        <th></th>

                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item: any) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>{item?.nome}</td>

                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item, roteEditar)}>Editar</Button></td>
                            <td><Button variant="danger" size="sm" onClick={(event) => deleteFuncionario(Number(item.id))}>Excluir</Button></td>


                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />

        </>
    );
}

export default TableFuncionariosMain;