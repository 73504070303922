import Footer from "../../../../components/Footer"
import FormNovaLimpeza from "../../../../components/Forms/Embarcacoes/Limpezas/FormNovaLimpeza";
import Header from "../../../../components/Header"


const PageNovaLimpeza = () => {
    return (
        <>
            <Header/>
            <h1>Page Nova Limpeza</h1>
            <FormNovaLimpeza/>

            <Footer/>
        </>
    )
}

export default PageNovaLimpeza;

