import react, { useEffect, useState } from "react";
import Header from "../../../../components/Header"
import Footer from "../../../../components/Footer";
import { Button, Table } from "react-bootstrap";
import FormNovaLimpeza from "../../../../components/Forms/Embarcacoes/Limpezas/FormNovaLimpeza";
import TableLimpezas from "../../../../components/Tables/TableLimpezaMain";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import Limpeza from "../../../../shared/Types/Limpeza";



const PageLimpezasMain = () => {

    const [dataTable, setDataTable] = useState<Limpeza[]>([]);


    async function getAllLimpezas() {
        const limpezas = await RequisicaoGet(`/limpezas/?page=${0}&size=50`);
        console.log('limpezas =', limpezas);
        setDataTable(limpezas.rows);
        console.log(`dataTable = `,dataTable);
    }

    useEffect(() => {getAllLimpezas()},[]);



    return (
        <>
            <Header/>
            <h1>Page Limpezas Main</h1>

            <Button href="/novaLimpeza">Nova Limpeza</Button>

            <TableLimpezas
                limpezas={dataTable}
                roteDetalhar='/detalharLimpeza'                
            />
            <Footer/>
        </>
    )
}

export default PageLimpezasMain;