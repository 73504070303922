import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const MainPage = () => {

    if (navigator.onLine) {
        console.log('online');
    } else {
        console.log('offline');
    }

    return (

        <>
            <Header></Header>
            <h1>PÁGINA PRINCIPAL</h1>

            <h2>Notícias</h2>
            <p>Fique atento: O Cadastro de novas embarcaões passou por atualizações e agora campos como Modelo e Motor devem ser preenchidos !</p>
            <Footer />
        </>

    )
}

export default MainPage;